<template>
  <div class="comparison-container">
    <div
      v-for="index in amountOfComparisons"
      :key="`${headerKey}-comparison-${index - 1}`"
      :set="(item = getComparisonItem(rowData, index))"
      :style="{ width: getWidth(headerKey) }"
    >
      <p>{{ item ? formatter(item[dataKey]) : MISSING_DATA_TEXT }}</p>
    </div>
  </div>
</template>
<script>
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  name: 'FormattedParagraphComparisonCell',
  components: {},
  props: {
    rowData: { type: Object, default: null },
    headerKey: { type: String, required: true },
    dataKey: { type: String, required: true },
    amountOfComparisons: { type: Number, required: true },
    formatter: { type: Function, required: true },
    getComparisonItem: { type: Function, required: true },
    getWidth: { type: Function, required: true },
  },
  setup() {
    return { MISSING_DATA_TEXT };
  },
};
</script>
<style lang="scss" scoped>
.comparison-container {
  display: flex;
}
</style>
