<template>
  <p>{{ formatter(dataKey === null ? data : data && data[dataKey]) }}</p>
</template>
<script>
export default {
  name: 'FormattedParagraphCell',
  components: {},
  props: {
    data: { type: [Number, Object], default: null },
    dataKey: { type: String, default: null },
    formatter: { type: Function, required: true },
  },
  setup() {},
};
</script>
<style lang="scss" scoped></style>
