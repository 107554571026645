<template>
  <TruncatedText
    v-if="orderItem === null || orderItem === undefined || orderItem.associatedIntegralItemsPricePerUnit === null"
    :name="`product-name-${product && product.id}`"
    :style="{ width: '100%' }"
  >
    {{ product && product.name }}
  </TruncatedText>
  <div v-else class="product-associated">
    <el-tooltip
      placement="top-end"
      effect="dark"
      :content="$t('terms.supplierTerms.supplierTermTable.hasAssociatedItems')"
    >
      <div class="icon">
        <HasAssociatedProductsIcon />
      </div>
    </el-tooltip>
    <TruncatedText :name="`product-name-${product && product.id}`" :style="{ width: '100%' }">
      {{ product && product.name }}
    </TruncatedText>
  </div>
</template>
<script>
import { computed } from 'vue';
import { HasAssociatedProductsIcon } from '@/assets/icons';
import { TruncatedText } from '@/modules/core/components';

export default {
  name: 'ProductNameCell',
  components: { HasAssociatedProductsIcon, TruncatedText },
  props: {
    rowData: { type: Object, default: null },
  },
  setup(props) {
    return {
      orderItem: computed(() => props.rowData?.maxDateOrderItem),
      product: computed(() => props.rowData?.product),
    };
  },
};
</script>
<style lang="scss">
.product-associated {
  display: flex;
  gap: 0.25rem;
  .icon {
    color: #9295a5;
  }
}
</style>
