<template>
  <el-tooltip
    v-if="orderItem && orderItem.associatedIntegralItemsPricePerUnit && orderItem.items.length > 0"
    placement="top-end"
    effect="dark"
  >
    <div>
      {{ formatMoney(orderItem.associatedIntegralItemsPricePerUnit) }}
    </div>
    <div slot="content">
      <div v-for="(item, index) in orderItem.items" :key="index">
        <span v-if="item.product !== null && item.type === 'associatedIntegral'">
          {{ item.product.name }}
          {{
            $direction === 'rtl'
              ? `(X${item.uom}${formatMoney(item.price)})`
              : `(${formatMoney(item.price)}X${item.uom})`
          }}
        </span>
      </div>
    </div>
  </el-tooltip>
  <p v-else>{{ MISSING_DATA_TEXT }}</p>
</template>
<script>
import { useCurrency } from '@/locale/useCurrency';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { formatCurrencyOrDash } from '../../../purchaseManagementFormatters';

export default {
  name: 'AmountOfAssociatedCell',
  components: {},
  props: {
    orderItem: { type: Object, default: null },
  },
  setup() {
    const { currencyFormat } = useCurrency();

    return {
      MISSING_DATA_TEXT,
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
    };
  },
};
</script>
<style lang="scss" scoped></style>
