<template>
  <p v-if="!rowData || rowData.quantity === 0">{{ MISSING_DATA_TEXT }}</p>
  <div v-else class="period-difference">
    <p>{{ formatMoney(rowData.totalPeriodDifference) }}</p>
    <PercentageChange v-if="shouldShowPercentage" :percentage-change="rowData.averagePercentageChange" />
  </div>
</template>
<script>
import { computed } from 'vue';

import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { useCurrency } from '@/locale/useCurrency';

import PercentageChange from '../../PercentageChange';

import { formatCurrencyOrDash } from '../../../purchaseManagementFormatters';

export default {
  name: 'PreviousPeriodDifferenceCell',
  components: { PercentageChange },
  props: {
    rowData: { type: Object, default: null },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    return {
      MISSING_DATA_TEXT,
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
      shouldShowPercentage: computed(() => {
        const value = props.rowData.averagePercentageChange;

        return value && props.rowData.totalDifference && Math.abs(props.rowData.totalDifference) > 0;
      }),
    };
  },
};
</script>
<style lang="scss" scoped>
.period-difference {
  display: flex;
  gap: 0.25rem;
}
</style>
