<template>
  <div class="annual-impact">
    <p>{{ formatMoney(data) }}</p>
    <p v-if="!data"></p>
    <SimulationTableArrowUpIcon v-else-if="data > 0" :size="20" fill="#E52044" />
    <SimulationTableArrowDownIcon v-else :size="20" fill="#00B37A" />
  </div>
</template>
<script>
import { SimulationTableArrowUpIcon, SimulationTableArrowDownIcon } from '@/assets/icons';
import { useCurrency } from '@/locale/useCurrency';

import { formatCurrencyOrDash } from '../../../purchaseManagementFormatters';
export default {
  name: 'AnnualImpactCell',
  components: { SimulationTableArrowUpIcon, SimulationTableArrowDownIcon },
  props: {
    data: { type: Number, default: null },
  },
  setup() {
    const { currencyFormat } = useCurrency();

    return {
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
    };
  },
};
</script>
<style lang="scss">
.average-price {
  display: flex;
  gap: 0.5rem;
}
</style>
