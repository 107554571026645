import { render, staticRenderFns } from "./AmountOfAssociatedCell.vue?vue&type=template&id=1dea1707&scoped=true"
import script from "./AmountOfAssociatedCell.vue?vue&type=script&lang=js"
export * from "./AmountOfAssociatedCell.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dea1707",
  null
  
)

export default component.exports