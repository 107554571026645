<template>
  <div class="average-period-price">
    <p>{{ formatMoney(pricedOrderItemAverage) }}</p>
    <el-tooltip v-if="averagePercentageChange !== null" placement="top" effect="dark">
      <PercentageChange :percentage-change="averagePercentageChange" />
      <div slot="content" class="percentage-change-tooltip">
        {{
          $t('terms.supplierTerms.supplierTermTable.percentageChange', {
            period: previousPeriodDateString,
            price: formatMoney(previousAveragePrice),
          })
        }}
      </div>
    </el-tooltip>
    <p v-else class="text-muted">
      {{ MISSING_DATA_TEXT }}
    </p>
  </div>
</template>
<script>
import { computed } from 'vue';

import { useCurrency } from '@/locale/useCurrency';

import { formatCurrencyOrDash } from '../../../purchaseManagementFormatters';
import PercentageChange from '../../PercentageChange';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

export default {
  name: 'AveragePeriodPriceCell',
  components: { PercentageChange },
  props: {
    rowData: { type: Object, default: null },
    previousPeriodDateString: { type: String, default: null },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    return {
      formatMoney: (number) => formatCurrencyOrDash(number, currencyFormat.value),
      MISSING_DATA_TEXT,
      pricedOrderItemAverage: computed(() => props.rowData?.pricedOrderItemAverage),
      previousAveragePrice: computed(() => props.rowData?.previousAveragePrice),
      averagePercentageChange: computed(() => props.rowData?.averagePercentageChange),
    };
  },
};
</script>
<style lang="scss" scoped></style>
